import React from "react";
import Pdf from "./Pdf";
import Notices from "./Notices";
import Results from "./Results";
import Res from "./Res";
import Rut from "./Rut";
import Rutins from "./Rutins";
import { useState, useEffect } from "react";
import TeachersProfile from "./TeachersProfile";
import {
  getDatabase,
  ref,
  push,
  set,
  onValue,
  remove,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
const Dashboard = () => {
  const [teachers, setTeachers] = useState([]);
  const [teacher, setTeacher] = useState({
    name: "",
    designation: "",
    email: "",
    idno: "",
    mobile: "",
    qulification: "",
  });
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTeachers(); // Fetch teachers on load
  }, []);

  const fetchTeachers = () => {
    const db = getDatabase();
    const teachersRef = ref(db, "teachers");

    onValue(teachersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const teachersList = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setTeachers(teachersList);
      }
    });
  };

  const uploadTeacherData = async (teacher, imageFile) => {
    const db = getDatabase();
    const storage = getStorage();

    try {
      let imageUrl = "";
      if (imageFile) {
        const imageRef = storageRef(
          storage,
          `teachers/${Date.now()}_${imageFile.name}`
        );
        await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(imageRef);
      }

      const newTeacherRef = push(ref(db, "teachers"));
      await set(newTeacherRef, {
        name: teacher.name,
        designation: teacher.designation,
        email: teacher.email,
        idno: teacher.idno,
        mobile: teacher.mobile,
        qulification: teacher.qulification,
        imageUrl: imageUrl,
      });

      console.log("Teacher added successfully!");
    } catch (error) {
      console.error("Error adding teacher:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!teacher.name || !image) {
      alert("Please fill all required fields and upload an image!");
      return;
    }

    setLoading(true);
    await uploadTeacherData(teacher, image);
    setLoading(false);

    // Reset form after submission
    setTeacher({
      name: "",
      designation: "",
      email: "",
      idno: "",
      mobile: "",
      qulification: "",
    });
    setImage(null);
    fetchTeachers(); // Fetch the updated list after adding a new teacher
  };

  const handleChange = (e) => {
    setTeacher({ ...teacher, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };
  const deleteTeacher = async (teacherId) => {
    const db = getDatabase();
    const teacherRef = ref(db, `teachers/${teacherId}`);

    try {
      await remove(teacherRef);
      console.log("Teacher deleted successfully!");
      fetchTeachers(); // Fetch the updated list after deletion
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };

  const handleDragEnd = (e) => {
    const updatedTeachers = [...teachers];
    const draggedTeacherIndex = e.target.dataset.index;
    const draggedTeacher = updatedTeachers.splice(draggedTeacherIndex, 1)[0];

    const targetTeacherIndex = e.target.closest(".teacher-card").dataset.index;
    updatedTeachers.splice(targetTeacherIndex, 0, draggedTeacher);

    setTeachers(updatedTeachers);
  };

  return (
    <div>
      <p className="text-xl mb-4 md:mb-6 lg:mb-10 md:text-2xl lg:text-3xl text-center text-white bg-black py-2">
        আপনার ওয়েবসাইটের বিভিন্ন তথ্য আপডেট করুন এখানে:
      </p>
      <p className="text-xl mb-1 md:text-2xl lg:text-3xl text-center text-white bg-violet-700 py-2">
        নোটিশ আপলোড:
      </p>
      <div className="grid md:grid-cols-2 gap-10 mb-4 lg:mb-10">
        <div className="border border-black bg-white">
          <p className="text-white bg-violet-600 p-2 text-center shadow-md">
            নোটিশ সংযোজন করুন (শুধুমাত্র pdf ফাইল):
          </p>
          <div className="bg-white p-4 flex flex-col justify-start gap-4 items-start">
            <Pdf />
            <p className="text-green-700 mx-auto">
              [ এখানে আপলোডকৃত ফাইলগুলো নোটিশ বোর্ড এ দেখা যাবে ]
            </p>
          </div>
        </div>
        <div className="border border-black bg-white">
          <p className="text-white bg-violet-600 p-2 text-center shadow-md">
            সকল নোটিশ:
          </p>
          <Notices />
          <div className="bg-white  p-4 flex flex-col justify-start gap-4 items-start"></div>
        </div>
      </div>
      <p className="text-xl mb-1 md:text-2xl lg:text-3xl text-center text-white bg-green-700 py-2">
        পরীক্ষার ফলাফল আপলোড:
      </p>
      <div className="grid md:grid-cols-2 gap-10 mb-4 lg:mb-10">
        <div className="border border-black bg-white">
          <p className="text-white bg-green-600 p-2 text-center shadow-md">
            পরীক্ষার ফলাফল সংযোজন করুন (শুধুমাত্র pdf ফাইল):
          </p>
          <div className="bg-white p-4 flex flex-col justify-start gap-4 items-start">
            <Res />
            <p className="text-green-700 mx-auto">
              [ এখানে আপলোডকৃত ফাইলগুলো আভ্যন্তরীন পরীক্ষার ফলাফল এ দেখা যাবে ]
            </p>
          </div>
        </div>
        <div className="border border-black bg-white">
          <p className="text-white bg-green-600 p-2 text-center shadow-md">
            বিদ্যালয়ের আভ্যন্তরীন পরীক্ষার ফলাফল:
          </p>
          <Results />
          <div className="bg-white  p-4 flex flex-col justify-start gap-4 items-start"></div>
        </div>
      </div>
      <p className="text-xl mb-1 md:text-2xl lg:text-3xl text-center text-white bg-indigo-700 py-2">
        ক্লাস রুটিন আপলোড:
      </p>
      <div className="grid md:grid-cols-2 gap-10">
        <div className="border border-black bg-white">
          <p className="text-white bg-indigo-600 p-2 text-center shadow-md">
            ক্লাস রুটিন সংযোজন করুন (শুধুমাত্র pdf ফাইল):
          </p>
          <div className="bg-white p-4 flex flex-col justify-start gap-4 items-start">
            <Rut />
            <p className="text-green-700 mx-auto">
              [ এখানে আপলোডকৃত ফাইলগুলো ক্লাস রুটিন সেকশনে এ দেখা যাবে ]
            </p>
          </div>
        </div>
        <div className="border border-black bg-white">
          <p className="text-white bg-indigo-600 p-2 text-center shadow-md">
            ক্লাস রুটিন:
          </p>
          <Rutins />
          <div className="bg-white  p-4 flex flex-col justify-start gap-4 items-start"></div>
        </div>
      </div>

      <div>
        <p className="text-xl mt-8 mb-1 md:text-2xl lg:text-3xl text-center text-white bg-violet-700 py-2">
          শিক্ষক-কর্মচারী আপডেট:
        </p>
        <form onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-4">
          <input
            type="text"
            name="name"
            placeholder="নাম"
            value={teacher.name}
            onChange={handleChange}
            className="p-3 border border-gray-400 rounded-xl"
            required
          />
          <input
            type="text"
            name="designation"
            placeholder="পদবী"
            value={teacher.designation}
            onChange={handleChange}
            className="p-3 border border-gray-400 rounded-xl"
            required
          />
          <input
            type="text"
            name="qulification"
            placeholder="শিক্ষাগত যোগ্যতা"
            value={teacher.qulification}
            onChange={handleChange}
            className="p-3 border border-gray-400 rounded-xl"
          />
          <input
            type="email"
            name="email"
            placeholder="ই-মেইল"
            value={teacher.email}
            onChange={handleChange}
            className="p-3 border border-gray-400 rounded-xl"
          />
          <input
            type="number"
            name="idno"
            placeholder="আই ডি"
            value={teacher.idno}
            onChange={handleChange}
            className="p-3 border border-gray-400 rounded-xl"
          />
          <input
            type="number"
            name="mobile"
            placeholder="মোবাইল"
            value={teacher.mobile}
            onChange={handleChange}
            className="p-3 border border-gray-400 rounded-xl"
          />

          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="p-3 border border-gray-400 rounded-xl"
          />
          <button
            type="submit"
            disabled={loading}
            className="bg-blue-500 text-white p-3 rounded-xl"
          >
            {loading ? "Uploading..." : "Add Teacher"}
          </button>
        </form>

        <div className="mt-10">
          <p className="text-xl md:text-2xl lg:text-3xl font-bold py-4 text-gray-600">
            Teachers List
          </p>
          <div className="grid lg:grid-cols-2 gap-4">
            {teachers.map((teacher, index) => (
              <div
                key={teacher.id}
                className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4 teacher-card"
                data-index={index}
                draggable="true"
                onDragEnd={handleDragEnd}
              >
                <img
                  src={teacher.imageUrl}
                  className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
                  alt={teacher.name}
                />
                <div className="rounded p-2 w-full">
                  <p className="text-xl font-semibold">{teacher.name}</p>
                  <div className="bg-gray-300 w-full h-[1px]"></div>
                  <div className="text-gray-600">
                    <p>Designation: {teacher.designation}</p>
                    <p>Email: {teacher.email}</p>
                    <p>ID: {teacher.idno}</p>
                    <p>Mobile: {teacher.mobile}</p>
                    <p>Qualification: {teacher.qulification}</p>
                  </div>
                  <button
                    onClick={() => deleteTeacher(teacher.id)}
                    className="bg-red-500 text-white p-2 rounded-xl mt-2"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
