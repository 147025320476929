import { useState, useEffect } from "react";
import {
  getDatabase,
  ref,
  push,
  set,
  onValue,
  remove,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

const TeachersProfile = () => {
  const [teachers, setTeachers] = useState([]);
  const [teacher, setTeacher] = useState({
    name: "",
    designation: "",
    email: "",
    idno: "",
    mobile: "",
    qulification: "",
  });
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTeachers(); // Fetch teachers on load
  }, []);

  const fetchTeachers = () => {
    const db = getDatabase();
    const teachersRef = ref(db, "teachers");

    onValue(teachersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const teachersList = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setTeachers(teachersList);
      }
    });
  };

  const uploadTeacherData = async (teacher, imageFile) => {
    const db = getDatabase();
    const storage = getStorage();

    try {
      let imageUrl = "";
      if (imageFile) {
        const imageRef = storageRef(
          storage,
          `teachers/${Date.now()}_${imageFile.name}`
        );
        await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(imageRef);
      }

      const newTeacherRef = push(ref(db, "teachers"));
      await set(newTeacherRef, {
        name: teacher.name,
        designation: teacher.designation,
        email: teacher.email,
        idno: teacher.idno,
        mobile: teacher.mobile,
        qulification: teacher.qulification,
        imageUrl: imageUrl,
      });

      console.log("Teacher added successfully!");
    } catch (error) {
      console.error("Error adding teacher:", error);
    }
  };

  const deleteTeacher = async (teacherId) => {
    const db = getDatabase();
    const teacherRef = ref(db, `teachers/${teacherId}`);

    try {
      await remove(teacherRef);
      console.log("Teacher deleted successfully!");
      fetchTeachers(); // Fetch updated list after deletion
    } catch (error) {
      console.error("Error deleting teacher:", error);
    }
  };

  const handleChange = (e) => {
    setTeacher({ ...teacher, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!teacher.name || !teacher.email || !image) {
      alert("Please fill all required fields and upload an image!");
      return;
    }

    setLoading(true);
    await uploadTeacherData(teacher, image);
    setLoading(false);

    // Reset form after submission
    setTeacher({
      name: "",
      designation: "",
      email: "",
      idno: "",
      mobile: "",
      qulification: "",
    });
    setImage(null);
    fetchTeachers(); // Fetch the updated list after adding a new teacher
  };

  return (
    <div className="py-4">
      <div className="grid lg:grid-cols-2 gap-4">
        {teachers.map((teacher, index) => (
          <div
            key={teacher.id}
            className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4"
          >
            <img
              src={teacher.imageUrl}
              className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
              alt={teacher.name}
            />
            <div className="rounded p-2 w-full">
              <p className="text-xl font-semibold">{teacher.name}</p>
              <div className="bg-gray-300 w-full h-[1px]"></div>
              <div className="text-gray-600">
                <p>পদবী: {teacher.designation}</p>
                <p>শিক্ষাগত যোগ্যতা: {teacher.qulification}</p>
                <p>ই-মেইল: {teacher.email}</p>
                <p>আই ডি: {teacher.idno}</p>
                <p>মোবাইল: {teacher.mobile}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeachersProfile;
